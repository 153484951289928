import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
  withXsrfConfiguration,
} from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { provideNativeDateAdapter } from '@angular/material/core';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { BayerInterceptor } from './core/interceptor/bayer.interceptor';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@environments/environment';

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/translate/');
}

export const provideTranslation = () => ({
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
});

export function loadScript() {
  return () =>
    new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = `https://cdns.gigya.com/JS/gigya.js?apiKey=${environment.apiKey}`;
      script.onload = () => resolve(true);
      document.body.appendChild(script);
    });
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    importProvidersFrom([
      TranslateModule.forRoot(provideTranslation()),
      BrowserAnimationsModule,
    ]),

    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideNativeDateAdapter(),
    provideAngularSvgIcon(),

    provideHttpClient(
      withXsrfConfiguration({
        cookieName: '_csrf_token',
        headerName: 'X-CSRF-TOKEN',
      }),
      withInterceptorsFromDi()
    ),
    { provide: HTTP_INTERCEPTORS, useClass: BayerInterceptor, multi: true },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'center',
        panelClass: ['black-snackbar'],
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadScript,
      multi: true,
    },
    DatePipe,
  ],
};
