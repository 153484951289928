import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TourMatMenuModule, TourService } from 'ngx-ui-tour-md-menu';
import { MatMenuModule } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { NgxSpinnerModule } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslateModule,
    TourMatMenuModule,
    RouterModule,
    MatMenuModule,
    MatIcon,
    MatCardModule,
    NgxSpinnerModule,
  ],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(public readonly tourService: TourService,
    private readonly router: Router
  ) { }
  redirectRouter() {
    this.tourService.end();
    this.goFullscreen();
    this.router.navigate(['/cdm-metrics/my-dashboard']);
   
  }
  goFullscreen() {
    const docElement: HTMLElement = document?.documentElement;

    if (document?.exitFullscreen) {
      document?.exitFullscreen();
    } else if ((docElement as any)?.mozCancelFullScreen) {
      (docElement as any)?.mozCancelFullScreen();
    } else if ((docElement as any)?.webkitExitFullscreen) {
      (docElement as any)?.webkitExitFullscreen();
    } else if ((docElement as any)?.msExitFullscreen) {
      (docElement as any)?.msExitFullscreen();
    }
  }
}
