import {
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EMPTY, Observable, catchError, finalize, throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class BayerInterceptor implements HttpInterceptor {
  isInvalidToken: boolean = false;

  constructor(
    private readonly httpxsrToken: HttpXsrfTokenExtractor,
    private readonly authService: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.isInvalidToken) {
      return EMPTY;
    }

    if (!req.url.endsWith('.json') && !req.url.endsWith('.svgr')) {
      const reqUrl = `${environment?.apiUrl}/${req.url}`;
      req = this.getRequest(req, reqUrl);
    }

    return next.handle(req).pipe(
      catchError((error: any) => {
        if (error?.status === 401) {
          if (!this.isInvalidToken) {
            alert('Your session has expired. Please log in again to continue.');
          }
          this.isInvalidToken = true;
          this.authService.clearStorage();
        }
        if (error?.status === 500) {
          console.log(error);
        }
        return throwError(() => error);
      }),
      finalize(() => { })
    );
  }

  getRequest(request: HttpRequest<any>, url: any) {
    const csrfToken: string = this.httpxsrToken.getToken() as string;
    let headers = new HttpHeaders();

    if (csrfToken) {
      headers = new HttpHeaders({ 'X-CSRF-TOKEN': csrfToken });
    }

    if (request.headers.has('isContentType')) {
      const acceptFileFormat: any = request?.headers?.get('Accept');
      headers = new HttpHeaders({ 'X-CSRF-TOKEN': csrfToken }).append(
        'Accept',
        acceptFileFormat
      );
    }

    return request.clone({
      withCredentials: true,
      url,
      headers,
    });
  }
}
